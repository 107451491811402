import { useContext } from 'react';
import { ShowOnSizesContext } from '../contexts';
import { ShowOnSizes } from '../types';

export function useShowOnSizes(): ShowOnSizes {
    const context = useContext(ShowOnSizesContext);
    if (!context) {
        throw new Error('useResponsiveSizes must be used within a ResponsiveSizesProvider');
    }
    return context;
}
